import React, { useState } from 'react';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styles from './patientdetail.module.css';
import arrow from '../../assets/images/arrowvector.png';
import folder from '../../assets/images/folder.png';
import imagepatient from '../../assets/images/image-5@2x.png';
import frame41 from '../../assets/svg/frame-41.svg';
import frame51 from '../../assets/svg/frame-51.svg';
import cloud from '../../assets/images/cloud-add.png';
import pdficon from '../../assets/images/pdficon.png';

interface DetailsProps {
  className?: string;
  onNavigate: (route: string) => void;
}


const Patientdetails: React.FC<DetailsProps> = ({ onNavigate }) => {

  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    }
  };

  // const startUpload = () => {
  //   if (files) {
  //     setUploading(true);
  //     // Simulate file upload progress
  //     const interval = setInterval(() => {
  //       setUploadProgress(prev => {
  //         if (prev >= 100) {
  //           clearInterval(interval);
  //           setUploading(false);
  //           return 100;
  //         }
  //         return prev + 10;
  //       });
  //     }, 100);
  //   }
  // };

  const cancelUpload = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploading(false);
  };

  const deleteFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (

    <div className={styles.patientDetail}>
      <main className={styles.content}>
        <section className={styles.pageContent}>
          <div className={styles.mainContent}>
            <div className={styles.breadcrumbWrapper}>
              <div className={styles.breadcrumbMain}>
                <img
                  className={styles.chevronRightIcon}
                  alt=""
                  src={arrow}
                />
                <div className={styles.breadcrumbItemsCore1}>
                  <div className={styles.breadcrumbItemsCore3}>
                    <div className={styles.text}>Patients</div>
                  </div>
                  <div className={styles.iconLabel}>
                    <img className={styles.icon} alt="" src={arrow} />
                  </div>
                </div>
                <div className={styles.leftContent}>
                  <div className={styles.iconLabel}>
                    <img
                      className={styles.icon}
                      loading="lazy"
                      alt=""
                      src={folder}
                    />
                    <div className={styles.textpatient}>Emmanuel Agu</div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className={styles.profileWrapper}>
            <div className={styles.profile}>
              <h2 className={styles.patientProfile}>{`Patient Profile `}</h2>
              <div className={styles.patientDetails}>
                <img
                  className={styles.image5Icon}
                  loading="lazy"
                  alt=""
                  src={imagepatient}
                />
                <div className={styles.detailsWrapper}>
                  <div className={styles.nameDob}>
                    <div className={styles.eleanoPena}>Eleano Pena</div>
                    <div className={styles.genderMaleM}>
                      Gender: Male (M) | DOB: 1994-07-22
                    </div>
                  </div>
                  <div className={styles.actionButtons}>
                    <button className={styles.buttonLabels}>
                      <div className={styles.buttontext}>Call</div>
                    </button>

                    <button className={styles.buttonLabels} style={{ marginLeft: '15px' }}>
                      <div className={styles.buttontext}>
                        Book Appointment
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.exams}>
            <div className={styles.examsContent}>
              <div className={styles.echocardiogramExams}>
                Echocardiogram Exams
              </div>
              <div className={styles.examList}>
                <div className={styles.examItem}>
                  <a className={styles.exam1}>Exam 1</a>
                  <div className={styles.examDetails}>
                    <div className={styles.examInfo}>
                      <img
                        className={styles.infoPlaceholderIcon}
                        loading="lazy"
                        alt=""
                        src={frame41}
                      />
                    </div>
                    <div className={styles.examDate}>
                      <div className={styles.dateValue}>1</div>
                    </div>
                    <div className={styles.examTime}>
                      <div className={styles.timeInfo}>
                        <img
                          className={styles.timePlaceholderIcon}
                          loading="lazy"
                          alt=""
                          src={frame51}
                        />
                      </div>
                      <div className={styles.timeValue}>1</div>
                    </div>
                  </div>
                </div>
                <div className={styles.monthsAgo}>
                  <div className={styles.timeWrapper}>
                    <div className={styles.timeValues}>
                      <div className={styles.timeLabel}>06/22/2004</div>
                    </div>
                    <div className={styles.timeValues1}>
                      <div className={styles.monthsAgo1}>6 months ago</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.container}>
            {/* <div className={styles.scanningActivity} onClick={() => handleButtonClick('/uploadselect')}></div> */}
            <div className={styles.fileuploadcontainer}>
              <div className={styles.iconContainer}>
                <img
                  className={styles.imageIcon}
                  loading="lazy"
                  alt=""
                  src={cloud}
                />
              </div>
              <div className={styles.scantext}>Choose a file or drag & drop it here</div>
              <div className={styles.scantext1}>JPEG, PNG, PDG, and MP4 formats, up to 50MB</div>
              <div className={styles.linecontainer}>
                <div className={styles.solidlineleft}></div>
                <div className={styles.scantext2}>or</div>
                <div className={styles.solidlineright}></div>
              </div>
              <div className={styles.uploadButton}>
                <input
                  type="file"
                  id="file-upload"
                  className={styles.fileInput}
                  onChange={handleFileChange}
                />
                <label htmlFor="file-upload" className={styles.uploadLabel}>
                  Add New Study
                </label>
              </div>
              {/* jjj */}

            </div>

            <div className={styles.uploadbarcontainer}>
              {files.map((file, index) => (
                <div className={styles.fileInfo} key={index}>
                <div className={styles.filenamedisplay}>
                  <img
                  className={styles.imagepdfIcon}
                  loading="lazy"
                  alt=""
                  src={pdficon}
                />
                  <div className={styles.fileName}>{file.name}</div>
                  
                  {uploading && (
                    <div className={styles.uploadProgress}>
                      <div
                        className={styles.progressBar}
                        style={{ width: `${uploadProgress[index] || 0}%` }}
                      />
                      <span className={styles.progressText}>
                        {uploadProgress[index] || 0}%
                      </span>
                    </div>
                  )}
                  <div className={styles.actions}>
                    {uploading ? (
                      <button
                        onClick={() => cancelUpload(index)}
                        className={styles.cancelButton}
                      >
                        <FaTimes /> Cancel
                      </button>
                    ) : (
                      <button
                        onClick={() => deleteFile(index)}
                        className={styles.deleteButton}
                      >
                        <FaTrash />
                      </button>
                    )}
                  </div>
                  </div>
                </div>
              ))}
            </div>

          </div>

        </section>
      </main>
    </div>
  );

};

export default Patientdetails;