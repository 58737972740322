import React from 'react';
import { useNavigate } from 'react-router-dom';
import image3Icon from '../assets/images/image-3@2x.png';
import image4Icon from '../assets/images//image-4@2x.png';
import  styles from './sidebar.module.css';
import framesvg from '../assets/svg/frame.svg';
import frame1svg from '../assets/svg/frame-1.svg';
import frame2svg from '../assets/svg/frame-2.svg';
import frame3svg from '../assets/svg/frame-3.svg';
import vectorsvg from '../assets/svg/Vector.svg';

interface SidebarProps {
  className?: string;
  onNavigate: (route: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ className, onNavigate }) => {
  const navigate = useNavigate();

  const handleButtonClick = (route: string) => {
    onNavigate(route);
    navigate(route); // Navigate to the route
  };

    return (
      <div className={[styles.sidebarNavigation, className].join(" ")}>
      <div className={styles.navigationHeader}>
        <div className={styles.logoBlock}>
          <img
            className={styles.image3Icon}
            loading="lazy"
            alt=""
            src={image3Icon}
          />
         
          <a className={styles.myocardi}>myocardi</a>
        </div>
        <div className={styles.icons}>
          <div className={styles.buttonIcon}>
            <img className={styles.icon} alt="" src={framesvg} />
          </div>
        </div>
      </div>
      <div className={styles.navigation}>
          <div className={styles.workspaces}>workspaces</div>
          <div className={styles.menuItem}>
          <button className={styles.menuButton} onClick={() => handleButtonClick('/patientlist')}>
            <div className={styles.leftContent}>
              <div className={styles.iconLabel}>
                <img className={styles.frameIcon} alt="" src={framesvg} />
                <div className={styles.myPatients}>My patients</div>
              </div>
            </div>
          </button>
          </div>
          <div className={styles.menuItem}>
          <button className={styles.menuButton}>
            <div className={styles.leftContent}>
              <div className={styles.iconLabel}>
                <img
                  className={styles.frameIcon}
                  loading="lazy"
                  alt=""
                  src={frame1svg}
                />
                <div className={styles.scanningActivity} onClick={() => handleButtonClick('/scanningactivity')}>Scanning Activity</div>
              </div>
            </div>
          </button>
          </div>
      </div>
      <div className={styles.navigation}>
        <div className={styles.title}>
          <div className={styles.informationCenter}>information center</div>
        </div>
        <div className={styles.menuItem}>
        <button className={styles.menuButton} onClick={() => handleButtonClick('/about')}>
          <div className={styles.leftContent}>
            <div className={styles.iconLabel}>
              <img
                className={styles.frameIcon}
                loading="lazy"
                alt=""
                src={frame2svg}
              />
              <div className={styles.about}>About</div>
            </div>
          </div>
        </button>
        </div>
        <div className={styles.menuItem}>
        <button className={styles.menuButton} onClick={() => handleButtonClick('/support')}>
          <div className={styles.leftContent}>
            <div className={styles.iconLabel}>
              <img
                className={styles.frameIcon3}
                loading="lazy"
                alt=""
                src={frame3svg}
              />
                <div className={styles.support}>Support</div>
            </div>
          </div>
        </button>
        </div>
        <div className={styles.account}>
          <div className={styles.avatarLabel}>
            <img
              className={styles.image4Icon}
              loading="lazy"
              alt=""
              src={image4Icon}
            />
            <div className={styles.nameEmail}>
              <div className={styles.administrationAccount}>
                Administration Account
              </div>
              <div className={styles.hellomyocardiio}>hello@myocardi.io
              <img
                  className={styles.frameIcon2}
                  loading="lazy"
                  alt=""
                  src={vectorsvg}
                />
              </div>
             
            </div>
          </div>
          
        </div>
      </div>
    </div>
    );
  };
  
  export default Sidebar;