import React, { useState } from 'react';
import styles from './signin.module.css';
import vectorsvg from './../../assets/images/signinimage.png';
import logo from './../../assets/images/Frame 12.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';


const Signin: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); 

  const handleSignUp = async () => {
        // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is valid
    if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
    }

    // Check if password is at least 8 characters
    if (password.length < 8) {
        setError("Password must be at least 8 characters long");
        return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
        setError("Passwords don't match");
        return;
    }

    if (!email || !password) {
        setError("Email and password are required");
        return;
    }

    try {
      const response = await fetch('http://localhost:5000/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${response.status} - ${errorData.error || 'Unknown error'}`);
        throw new Error(errorData.error || 'Error registering user');
      }
  
      const data = await response.json();
      alert(`Success: ${data.msg || 'Registration successful'}`);
      console.log('Registration successful');
      navigate('/signinpage');
    } catch (error) {
      alert(`Caught an error:`);
      console.log('Error:', error);
    }
    

    // Handle registration logic here
    console.log('Registering with', { email, password });
  };

  const handleSignInWithSocial = (platform: string) => {
    // Handle social media sign-in (e.g., Google, Facebook)
    console.log('Signing in with', platform);
  };

  return (
    <div className={styles.container}>
      {/* Left side with image */}
      <div className={styles.imageSection}>
        <img src={vectorsvg} alt="Registration Illustration" className={styles.backgroundImage}/>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>

      {/* Right side with registration form */}
      <div className={styles.formSection}>
      <h2>Registration</h2>
      {/* <div className={styles.registration}>
        <h2>Registration</h2>
      </div> */}

        <form>
          <div className={styles.formGroup}>
            <label className={styles.textstyle}>Your Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your mail for registration"
              required
              className={styles.textcontrol}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.textstyle}>Create password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Your account password"
              required
              className={styles.textcontrol}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.textstyle}>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Repeat password"
              required
              className={styles.textcontrol}
            />
          </div>

          {error && <p className={styles.error}>{error}</p>}

          <button type="button" className={styles.signupButton} onClick={handleSignUp}>
            Sign Up
          </button>
        </form>

        <div className={styles.socialSignin}>
          <p className={styles.textstyle}>Sign with social media:</p>
          <button onClick={() => handleSignInWithSocial('Google')} className={styles.facebook}>
            
          </button>
          <button onClick={() => handleSignInWithSocial('Google')} className={styles.git}>
            
          </button>
          <button onClick={() => handleSignInWithSocial('Facebook')} className={styles.google}>
           
          </button>
        </div>

        <div className={styles.signinLink}>
        Already have an account? <Link to="/signinpage">Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default Signin;
