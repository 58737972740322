import React from 'react';
import  styles from './patientlist.module.css';
import Filter from '../../components/filter';
import dropdownarrow from '../../assets/images/dropdownarrow.png';
import stateika2 from '../../assets/images/stateika2.png';
import { useNavigate } from 'react-router-dom';

const PatientList: React.FC = () => {
  // return <div>Patient list page in progress</div>;
  const navigate = useNavigate();

  const handleRowClick = (patientId: string) => {
    // alert(`Row clicked with patient ID: ${patientId}`);
    navigate(`/patientdetails/${patientId}`);
  };

  return (
    <div className={styles.patientOverview}>
      
      <main className={styles.content}>
        <section className={styles.filterWrapper}>
          <Filter />
          <div className={styles.div}>
            <div className={styles.row} >
              <div className={styles.header}>
                <div className={styles.nameicon}>
                  <div className={styles.headin}>Name</div>
                  <img
                    className={styles.downArrow1Icon2}
                    alt=""
                    src={dropdownarrow}
                  />
                </div>
              </div>
              <div className={styles.header}>
                <div className={styles.nameicon}>
                  <div className={styles.headin}>Diagnosis</div>
                  <img
                    className={styles.downArrow1Icon2}
                    alt=""
                    src={dropdownarrow}
                  />
                </div>
              </div>
              <div className={styles.header}>
                <div className={styles.nameicon}>
                  <div className={styles.headin}>DOB</div>
                  <img
                    className={styles.downArrow1Icon2}
                    alt=""
                    src={dropdownarrow}
                  />
                </div>
              </div>
              <div className={styles.header}>
                <div className={styles.nameicon}>
                  <div className={styles.headin}>Phone #</div>
                  <img
                    className={styles.downArrow1Icon2}
                    alt=""
                    src={dropdownarrow}
                  />
                </div>
              </div>
              <div className={styles.header}>
                <div className={styles.nameicon}>
                  <div className={styles.headin}>Address</div>
                  <img
                    className={styles.downArrow1Icon2}
                    alt=""
                    src={dropdownarrow}
                  />
                </div>
              </div>
              
            </div>
            <div className={styles.row1} onClick={() => handleRowClick('12345')}>
              {/* <div className='row-item'> */}
              <div className={styles.divname}>
                <div className={styles.ellipseParent}>
                  <div className={styles.frameChild} />
                  <img
                    className={styles.stateika21Icon}
                    loading="lazy"
                    alt=""
                    src={stateika2}
                  />
                  <div className={styles.patientnametext}>Andrew Hiya</div>
                </div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>Heart Attack</div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>02/07/2007</div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>(617) 470-0550</div>
              </div>
              <div className={styles.header1}>
                  <div className={styles.patientnametext}>Tampa, FL</div>
              </div>
             {/* </div> */}
            </div>
            <div className={styles.row1} onClick={() => handleRowClick('6789')}>
              {/* <div className='row-item'> */}
              <div className={styles.divname}>
                <div className={styles.ellipseParent}>
                  <div className={styles.frameChild} />
                  <img
                    className={styles.stateika21Icon}
                    loading="lazy"
                    alt=""
                    src={stateika2}
                  />
                  <div className={styles.patientnametext}>Andrew Hiya</div>
                </div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>Heart Attack</div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>02/07/2007</div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>(617) 470-0550</div>
              </div>
              <div className={styles.header1}>
                  <div className={styles.patientnametext}>Tampa, FL</div>
              </div>
             {/* </div> */}
            </div>
            <div className={styles.row1} onClick={() => handleRowClick('232')}>
              {/* <div className='row-item'> */}
              <div className={styles.divname}>
                <div className={styles.ellipseParent}>
                  <div className={styles.frameChild} />
                  <img
                    className={styles.stateika21Icon}
                    loading="lazy"
                    alt=""
                    src={stateika2}
                  />
                  <div className={styles.patientnametext}>Andrew Hiya</div>
                </div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>Heart Attack</div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>02/07/2007</div>
              </div>
              <div className={styles.header1}>
                <div className={styles.patientnametext}>(617) 470-0550</div>
              </div>
              <div className={styles.header1}>
                  <div className={styles.patientnametext}>Tampa, FL</div>
              </div>
             {/* </div> */}
            </div>
        
           
         
          </div>
        </section>
      </main>
    </div>
  );
};

export default PatientList;