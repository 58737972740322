import React, { useState } from 'react';
import styles from './about.module.css';
import vectorsvg from './../../assets/svg/Vector.svg';

const About: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={styles.patientDetail}>

      <main className={styles.content}>
        <section className={styles.pageContent}>
        
          <div className={styles.breadcrumbMain}>
            <div className={styles.breadcrumbItemsCore1}>
              <div className={styles.breadcrumbItemsCore3}>
                <div className={styles.text}>About</div>
              </div>
            </div>
          </div>
       
          <div className={styles.exams}>
            <div className={styles.examsContent}>

              <div className={styles.mainheading}>
                <div className={styles.echocardiogramExams}>
                  About Myocardi
                </div>
              </div>
             
              <div className={styles.examItem}>
                <div className={styles.subtitle}>
                  Subscription
                </div>
                <div className={styles.examList}>
                  <div className={styles.examItem}>
                    <div className={styles.subheading}>
                      <div className={styles.subtitle1}>
                        Subscription Expiry Date
                      </div>
                    </div>
                    <div className={styles.subtitle1}>
                      Contact management at email@email.com for change
                    </div>
                    <div className={styles.buttonheader}>
                      <button className={styles.buttonLabels} style={{ marginLeft: '10px' }}>
                        <div className={styles.buttontext}>
                          Contact
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.headerItem}>
                <div className={styles.subtitle}>
                  App Release Version
                </div>
                <div className={styles.listcontentBox}>
                  <h1 className={styles.releaseText}>Wednesday, June 26</h1>
                  <ul className={styles.releaseContentText}>
                      <li>Released first app version with all functionalities</li>
                      <li>Updated design attributes and established frontend</li>
                  </ul> 
                  
                </div>
                
              </div>
              <div className={styles.collapsibleList}>
                  {/* <div className={styles.collapsible}> */}
                    <button className={`${styles.collapsibleButton} ${isOpen ? styles.active : ''}`} onClick={toggleCollapse}>
                    Collapsible Header
                    {/* <span className={styles.arrow}>{isOpen ? '▲' : '▼'}</span>  */}
                    <span className={styles.arrow}>{isOpen ? '^' : '>'}</span> {/* Down arrow */}
                    </button>
                    <div className={`${styles.collapsibleContent} ${isOpen ? styles.show : ''}`}>
                      <p>This is the collapsible content. It will be hidden or shown when the button is clicked.</p>
                    </div>
                  {/* </div> */}
                </div>
              <div className={styles.legalInfoItem}>
                <div className={styles.subtitle}>
                  Legal Information
                </div>
                <div className={styles.legalInfoList}>
                <div className={styles.legalContentText}>Privacy Policy</div>
                <img
                  className={styles.frameIcon2}
                  loading="lazy"
                  alt=""
                  src={vectorsvg}
                />
                </div>
                <div className={styles.listGap}></div>
                <div className={styles.legalInfoList}>
                <div className={styles.legalContentText}>Terms of Service</div>
                <img
                  className={styles.frameIcon2}
                  loading="lazy"
                  alt=""
                  src={vectorsvg}
                />
                </div>
              </div>
            </div>
          </div>
         
        </section>
      </main>
    </div>
  );
};

export default About;