
import React, { useState } from 'react';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styles from './uploadselect.module.css';
import arrow from '../../assets/images/arrowvector.png';
import folder from '../../assets/images/folder.png';
import imagepatient from '../../assets/images/image-5@2x.png';
import frame41 from '../../assets/svg/frame-41.svg';
import frame51 from '../../assets/svg/frame-51.svg';

const Uploadselect: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0] || null;
        setFile(selectedFile);
    };

    const handleCancel = () => {
        setFile(null);
    };

    const handleDelete = () => {
        setFile(null);
    };
    return (

        <div className={styles.patientDetail}>

            <main className={styles.content}>
                <section className={styles.pageContent}>
                    {/* <div className={styles.mainContent}> */}
                    {/* <div className={styles.breadcrumbWrapper}> */}
                    <div className={styles.breadcrumbMain}>
                        <img
                            className={styles.chevronRightIcon}
                            alt=""
                            src={arrow}
                        />
                        <div className={styles.breadcrumbItemsCore1}>

                            <div className={styles.breadcrumbItemsCore3}>
                                <div className={styles.text}>Patients</div>
                            </div>
                            <div className={styles.iconLabel}>
                                <img className={styles.icon} alt="" src={arrow} />
                            </div>
                        </div>

                        <div className={styles.leftContent}>

                            <div className={styles.iconLabel}>
                                <img
                                    className={styles.icon}
                                    loading="lazy"
                                    alt=""
                                    src={folder}
                                />

                                <div className={styles.text}>Emmanuel Agu</div>
                            </div>
                        </div>
                        <div className={styles.iconLabel}>
                            <img className={styles.icon} alt="" src={arrow} />
                        </div>
                        <div className={styles.breadcrumbItemsCore3}>
                            <div className={styles.text1}>Upload and Select</div>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    <div className={styles.exams}>
                        <div className={styles.examsContent}>
                            <div className={styles.mainheading}>
                            <div className={styles.echocardiogramExams}>
                                Upload and Select
                            </div>
                            </div>
                            {/* <div className={styles.examList}> */}
                            <div className={styles.examItem}>
                                <div className={styles.subtitle}>
                                    Exam Selection
                                </div>
                                <div className={styles.examList}>
                                    <div className={styles.examItem}>
                                        <div className={styles.subheading}>
                                        <div className={styles.subtitle1}>
                                            Select Exam Type
                                        </div>
                                        </div>
                                        <div className="checkboxheader">
                                        <div className="checkbox-container">
                                            <div className="checkbox-row">
                                                <div className="checkboxcolumn"> 
                                                <label>
                                                    <input type="checkbox" name="option1" />
                                                    Left Ventricle Obstruction
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="option2" />
                                                    Minimal Wall Thickness
                                                </label>
                                                </div>
                                            </div>
                                            <div className="checkbox-row">
                                                <label>
                                                    <input type="checkbox" name="option3" />
                                                    Ejection Fraction
                                                </label>
                                                <label>
                                                    <input type="checkbox" name="option4" />
                                                    Hypertrophic Cardiomyopathy
                                                </label>
                                            </div>
                                        </div>
                                        </div>
                                        <div className={styles.buttonheader}>
                                        <button className={styles.buttonLabels} style={{ marginLeft: '20px' }}>
                                            <div className={styles.buttontext}>
                                                Update
                                            </div>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.subtitle}>
                                    Echo Upload
                                </div>

                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.uploadButton}>
                            <input
                                type="file"
                                id="file-upload"
                                className={styles.fileInput}
                                onChange={handleFileChange}
                            />
                            <label htmlFor="file-upload" className={styles.uploadLabel}>
                                Upload File
                            </label>
                        </div>
                        {file && (
                            <div className={styles.fileDetails}>
                                <div className={styles.fileInfo}>
                                    <span className={styles.fileName}>{file.name}</span>
                                    <span className={styles.fileSize}>{(file.size / 1024).toFixed(2)} KB</span>
                                </div>
                                <div className={styles.actions}>
                                    {/* <FaTimes
                  styles={{ cursor: 'pointer', color: '#000', fontSize: '24px', marginRight: '10px' }}
                  onClick={handleCancel}
                />
                <FaTrash
                  styles={{ cursor: 'pointer', color: '#000', fontSize: '24px' }}
                  onClick={handleDelete}
                /> */}
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Uploadselect;