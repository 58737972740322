import React, { useState } from 'react';
import styles from "./filter.module.css";
import categories from '../assets/images/categories.png';
import dob from '../assets/images/dob.png';
import priorities from '../assets/images/priorities.png';
import dropdownarrow from '../assets/images/dropdownarrow.png';
import trailingicon from '../assets/svg/trailingicon.svg';

export type FilterType = {
  className?: string;
};

const Filter: React.FC<FilterType> = ({ className = "" }) => {

  const [isCategoryListOpen, setIsCategoryListOpen] = useState(false);

  const handleCategoryClick = () => {
    setIsCategoryListOpen(!isCategoryListOpen);
  };

  return (
    <div className={[styles.filter, className].join(" ")}>
    <div className={styles.filterBy}>Filter by</div>
    <nav className={styles.filterDropdown}>
   
    <div className={styles.component156}>
        <div className={styles.stateLayer}>
          <img className={styles.frameIcon} alt="" src={dob} />
          <div className={styles.dropdownLabels}>DOB</div>
          <img className={styles.frameIcon1} alt="" src={dropdownarrow} />
        </div>
     </div>
      {/* <div className={styles.componentcategory}>
        <div className={styles.stateLayercategory}>
          <img className={styles.frameIcon} alt="" src={categories} />
          <div className={styles.dropdownLabels}>Categories</div>
          <img className={styles.frameIcon1} alt="" src={dropdownarrow} />
        </div>
        </div> */}
        <button className={styles.componentcategory} onClick={handleCategoryClick}>
          {/* <div className={styles.componentcategory}> */}
        <div className={styles.stateLayercategory}>
          <img className={styles.frameIcon} alt="" src={categories} />
          <div className={styles.dropdownLabels}>Categories</div>
          <img className={styles.frameIcon1} alt="" src={dropdownarrow} />
        </div>
        {/* </div> */}
      </button>
      {isCategoryListOpen && (
        <div className={styles.categoryList}>
          {/* <ul>
            <li>PatientID</li>
            <li>Gender</li>
            <li>HCM</li>
            <li>LVOT</li>
            <li>MWT</li>
            <li>Exam Date</li>
          </ul> */}
        </div>
      )}
        <div className={styles.component156}>
        <div className={styles.stateLayer}>
          <img className={styles.frameIcon} alt="" src={priorities} />
          <div className={styles.dropdownLabels}>Priority</div>
          <img className={styles.frameIcon1} alt="" src={dropdownarrow} />
        </div>
        </div>
    </nav>
    <div className={styles.horizontalline}></div>

 
    <div className={styles.filterChip}>
      <div className={styles.component157}>
        <div className={styles.inputChipDark1}>
          
              <div className={styles.dropdownLabels}>06/22/2004 - 06/28/2004</div>
              <img
                className={styles.trailingIcon}
                loading="lazy"
                alt=""
                src={trailingicon}
              />
           
        </div>
      </div>
      <div className={styles.component163}>
        <div className={styles.inputChipDark1}>
        
              <a className={styles.dropdownLabels}>High</a>
              <img
                className={styles.trailingIcon}
                loading="lazy"
                alt=""
                src={trailingicon}
              />
          
        </div>
      </div>
      <div className={styles.component163}>
        <button className={styles.inputChipDark1}>
          <div className={styles.stateLayer7}>
            <a className={styles.dropdownLabels}>Clear All</a>
          </div>
        </button>
      </div>
    </div>
  </div>
);
};

export default Filter;