import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/sidebar';
import Patientdetails from './pages/patientdetails/patientdetails';
import PatientList from './pages/patientlist/patientlist';
import ScanningActivity from './pages/scanningactivity/scanningactivity';
import About from './pages/about/about';
import Support from './pages/support/support';
import Uploadselect from './pages/uploadselect/uploadselect';
import Signin from './pages/signin/signin';
import SigninPage from './pages/signin/siginpage';
import './App.css';

// Main App Component
const App: React.FC = () => {
  const [activeRoute, setActiveRoute] = useState<string | null>('/signin'); // Default route is '/signin'

  const handleNavigation = (route: string) => {
    setActiveRoute(route);
  };

  const location = useLocation(); 
//commit line 
  // Decide whether to show Sidebar based on the current route
  const showSidebar = !['/signin', '/signinpage'].includes(location.pathname);

  return (
    <div className="app-container">
      {/* Conditionally render Sidebar based on activeRoute */}
      {showSidebar && <Sidebar onNavigate={handleNavigation} />}
     
      <div className="content">
        <Routes>
          {/* Default route points to /signin */}
          <Route path="/" element={<Navigate to="/signin" />} />
          
          {/* Signin page */}
          <Route path="/signin" element={<Signin />} />
           {/* SigninPage page */}
           <Route path="/signinpage" element={<SigninPage />} />

          {/* Other routes */}
          <Route path="/patientlist" element={<PatientList />} />
          <Route
            path="/patientdetails/:id"
            element={<Patientdetails onNavigate={handleNavigation} />}
          /> 
          <Route path="/scanningactivity" element={<ScanningActivity />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />
          <Route path="/uploadselect" element={<Uploadselect />} />
        </Routes>
      </div>
    </div>
  );
};

// AppWrapper component that wraps the App with Router
const AppWrapper: React.FC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
