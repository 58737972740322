import React, { useState } from 'react';
import styles from './signin.module.css';
import vectorsvg from './../../assets/images/signinimage.png';
import logo from './../../assets/images/Frame 12.png';
import { useNavigate } from 'react-router-dom';

const SigninPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); 
  const [error, setError] = useState('');

  // const handleSignIn = () => {
  //   navigate('/patientlist');

  //   console.log('Registering with', { email, password });
  // };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/api/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error signing in');
      }

      const data = await response.json();
      alert(data.msg || 'Sign-in successful');
      console.log('Sign-in successful', data);

      // Save the JWT token to localStorage or cookie (optional)
      localStorage.setItem('token', data.token);

      // Navigate to another page (e.g., dashboard)
      navigate('/patientlist');
    } catch (error) {
      alert(`Error: `);
      console.error('Sign-in failed', error);
    }
  };

  return (
    <div className={styles.container}>
      {/* Left side with image */}
      <div className={styles.imageSection}>
        <img src={vectorsvg} alt="Registration Illustration" className={styles.backgroundImage}/>
        <img src={logo} alt="Logo" className={styles.logo} />
      </div>

      {/* Right side with registration form */}
      <div className={styles.formSection}>
     
      {/* <div className={styles.registration}>
        <h2>Registration</h2>
      </div> */}

        <form>
          <div className={styles.formGroup}>
            <label className={styles.textstyle}>Enter Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Your mail for sigin"
              required
              className={styles.textcontrol}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.textstyle}>Enter password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Your account password"
              required
              className={styles.textcontrol}
            />
          </div>
         

          {error && <p className={styles.error}>{error}</p>}

          <button type="button" className={styles.signupButton} onClick={handleSignIn}>
            Sign In
          </button>
        </form>

       
      </div>
    </div>
  );
};

export default SigninPage;
