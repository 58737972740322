import React, { useState } from 'react';
import styles from './support.module.css';
import vectorsvg from './../../assets/svg/Vector.svg';

const Support: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form Data Submitted:', formData);
  };
  return (
    <div className={styles.patientDetail}>

      <main className={styles.content}>
        <section className={styles.pageContent}>

          <div className={styles.breadcrumbMain}>
            <div className={styles.breadcrumbItemsCore1}>
              <div className={styles.breadcrumbItemsCore3}>
                <div className={styles.text}>Support</div>
              </div>
            </div>
          </div>

          <div className={styles.exams}>
            <div className={styles.examsContent}>

              <div className={styles.mainheading}>
                <div className={styles.echocardiogramExams}>
                  Support Center
                </div>
              </div>
              <div className={styles.exampleItemClass}>
                <div className={styles.examItem}>
                  <div className={styles.subtitle}>
                    Submit Support Ticket
                  </div>
                  <div className={styles.subtitleContent}>
                    <form className={styles.form} onSubmit={handleSubmit}>
                      <div className={styles.formGroup}>
                        <label htmlFor="name" className={styles.label}>Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          className={styles.input}
                          required
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label htmlFor="email" className={styles.label}>Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className={styles.input}
                          required
                        />
                      </div>

                      <div className={styles.formGroupTextarea}>
                        <label htmlFor="message" className={styles.messagelabel}>Message</label>
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          className={styles.textarea}
                          required
                        />
                      </div>

                      <button type="submit" className={styles.submitButton}>Submit</button>
                    </form>
                  </div>
                  
                </div>
                <div className={styles.examItem}>
                  <div className={styles.subtitle}>
                    More Information
                  </div>
                  <div className={styles.moreInfoContent}>
                  <div className={styles.legalInfoItem}>
               
                <div className={styles.legalInfoList}>
                <div className={styles.legalContentText}>User Manual</div>
                <img
                  className={styles.frameIcon2}
                  loading="lazy"
                  alt=""
                  src={vectorsvg}
                />
                </div>
                <div className={styles.listGap}></div>
                <div className={styles.legalInfoList}>
                <div className={styles.legalContentText}>Website</div>
                <img
                  className={styles.frameIcon2}
                  loading="lazy"
                  alt=""
                  src={vectorsvg}
                />
                </div>
                <div className={styles.listGap}></div>
                <div className={styles.legalInfoList}>
                <div className={styles.legalContentText}>Contact Us</div>
                <img
                  className={styles.frameIcon2}
                  loading="lazy"
                  alt=""
                  src={vectorsvg}
                />
                </div>
              </div>
                  </div>
                </div>
              </div>

              <div className={styles.fnqItem}>
                  Frequently Asked Questions
              </div>

              <div className={styles.collapsibleList}>
                {/* <div className={styles.collapsible}> */}
                <button className={`${styles.collapsibleButton} ${isOpen ? styles.active : ''}`} onClick={toggleCollapse}>
                  How do I upload a new echocardiogram?
                  <span className={styles.arrow}>{isOpen ? '▲' : '▼'}</span>
                  {/* <span className={styles.arrow}>{isOpen ? '^' : '>'}</span> */}
                </button>
                <div className={`${styles.collapsibleContent} ${isOpen ? styles.show : ''}`}>
                  <p>This is the collapsible content. It will be hidden or shown when the button is clicked.</p>
                </div>
                {/* </div> */}
              </div>
              <div className={styles.listGap}></div>
              <div className={styles.collapsibleList}>
                {/* <div className={styles.collapsible}> */}
                <button className={`${styles.collapsibleButton} ${isOpen ? styles.active : ''}`} onClick={toggleCollapse}>
                  How do I upload a new echocardiogram?
                  <span className={styles.arrow}>{isOpen ? '▲' : '▼'}</span>
                  {/* <span className={styles.arrow}>{isOpen ? '^' : '>'}</span> */}
                </button>
                <div className={`${styles.collapsibleContent} ${isOpen ? styles.show : ''}`}>
                  <p>This is the collapsible content. It will be hidden or shown when the button is clicked.</p>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

        </section>
      </main>
    </div>
  );
};

export default Support;